.paypal_row {

    display:flex;
    align-items: center;
    margin:1rem 0 0 0;
}

.paypal_box {

    flex:1 1 50%;
    vertical-align:middle;
}

.paypal_box.paypal_buttons {
    flex:0 1 20vw;
    padding-top:2rem;
}

.paypal_box.paypal_text label {
    display:block;
    height:3rem;
    margin:1rem;
    font-size:1.2rem;
    text-align:left;
    cursor:pointer;
    border-radius:.5rem;
}

.paypal_box.paypal_text h2 {
    margin:2rem 0 1rem 0;
}

.paypal_box.paypal_text label.selected {
}

.paypal_box.paypal_text label .imagebox {
    height:3rem;
    width:3rem;
    margin:0 1rem 0 0;
    display:inline-block;
    vertical-align: middle;
}

.paypal_box.paypal_text label img {
    opacity:0;
    height:3rem;
    transition:opacity 1s;
}

.paypal_box.paypal_text label.selected img {
    opacity:1;
    transition:opacity 1s;
}

.paypal_box.paypal_text label.selected {
    font-weight:bold;
}

@media screen and (max-width: 767px) {

    .paypal_row {
        display:block;
    }
}